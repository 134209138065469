<template>
    <div>
        <el-date-picker
                ref="date-picker"
                :class="datePickerUniqueClass + '-input'"
                v-model="contractDateDetail.date"
                type="datetime"
                :placeholder="placeholder ? placeholder : '请选择日期'"
                style="width: 100%"
                format="YYYY-MM-DD"
                :popper-class="datePickerUniqueClass + '-popper'"
                @change="onDatePickerChange"
                @blur="handleInputBlur"
                @focus="handleInputFocus"
                prefix-icon="none"
                :clearable="false"
        >
        </el-date-picker>

        <div class="period-time-component" :class="datePickerUniqueClass">
            <div class="period-time-checkbox-wrapper">
                <el-checkbox v-model="contractDateDetail.isPeriodTime"
                             label="按"
                             size="large"
                             class="period-time-checkbox"
                             @change="periodTimeEffectiveChange">

                </el-checkbox>
            </div>
            <div class="period-time-select-wrapper">
                <el-select v-model="contractDateDetail.periodTimeUnit"
                           @change="onPeriodTimeUnitChange"
                           class="m-2"
                           placeholder="Select"
                           size="small"
                           style="width: 80px; margin-right: 5px">
                    <el-option
                            v-for="item in periodTimeUnitOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <label>重复</label>
                <el-input-number v-if="contractDateDetail.isPeriodTime"
                                 v-model="contractDateDetail.periodTimeNumber"
                                 @change="onPeriodTimeNumberChange"
                                 :min="1"
                                 :max="100"
                                 :controls="false"
                                 size="small"
                                 style="margin-left: 5px; margin-right: 5px; width: 80px"/>
                <label v-if="contractDateDetail.isPeriodTime">次</label>
                <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top"
                        content="以年、季度、月为单位，根据输入的次数，进行周期性提醒">
                    <i class="el-icon-info" style="position:absolute;"></i>
                </el-tooltip>
            </div>
        </div>
    </div>

</template>

<script>
    import {colorLog, getUniqueId, MetaMessage} from "../../util";
    import {searchContractsAndFiles} from "../../api/api";
    import {formatDateFromTimestamp} from "../../constant/util";
    import {ContractDatePeriodTimeUnit} from "../../constant/contract";

    export default {
        name: "DatetimePicker",
        props: [
            'contractDateInfo',
            'timeChange',
            'placeholder',
            'recoverable',
            'inputInnerPaddingLeft'
        ],
        mounted() {
            this.$nextTick(() => {
                // 得到下拉弹窗的dom
                this.datePickerPopperDom = document.querySelector('.' + this.datePickerUniqueClass + '-popper');

                // input输入框
                const inputInner = document.querySelector(`.${this.datePickerUniqueClass + '-input'} .el-input__inner`);
                inputInner.style.paddingLeft = this.inputInnerPaddingLeft || '21px';

                // 日期输入框
                this.datetimeInputInner = this.datePickerPopperDom.querySelector(`.el-input__inner`);
                this.datetimeInputInner.disabled = true;
                this.datetimeInputInner.style.backgroundColor = '#f7f7f5';
                // console.log('inputInnerPaddingLeft ==== ', this.inputInnerPaddingLeft);
                // console.log('datetimeInputInner ==== ', this.datetimeInputInner);
                // if (this.inputInnerPaddingLeft) {
                //     console.log('xxxxxxxxxxxxxxxxx')
                //     this.datetimeInputInner.style.paddingLeft = this.inputInnerPaddingLeft
                // } else {
                //     console.log('aaaaaaaaaaaaaaaaaa')
                //     this.datetimeInputInner.style.paddingLeft = '21px';
                // }

                // 去掉input输入框的前缀
                // const inputPrefix = document.querySelector(`.${this.datePickerUniqueClass + '-input'} .el-input__prefix`);
                // inputPrefix.style.display = "none";

                // 去掉时间的显示框
                const timeHeader = this.datePickerPopperDom.querySelector(`.el-date-picker__time-header`);
                if (timeHeader.children.length === 2) {
                    timeHeader.lastChild.style.display = "none";
                }

                //给确定按钮绑定事件
                let confirmBtn = this.datePickerPopperDom.querySelector('.el-button--default.el-picker-panel__link-btn');
                confirmBtn.addEventListener('click', (e) => {
                    //用来阻止blur事件还原
                    this.disableRecover = true;
                }, {capture: true});

                const pickerFooter = document.querySelector(`.${this.datePickerUniqueClass + '-popper'} .el-picker-panel__footer`);
                const button1 = pickerFooter.firstChild;
                const periodTimeComponent = document.querySelector(`.${this.datePickerUniqueClass}.period-time-component`);
                if (!this.contractDateDetail.id || this.contractDateDetail.id < 0) {
                    pickerFooter.insertBefore(periodTimeComponent, button1);
                    const button2 = pickerFooter.lastChild;

                    button2.style.marginRight = '10px';

                    if (this.contractDateDetail.timestamp) {
                        this.contractDateDetail.date = new Date(this.contractDateDetail.timestamp);
                        this.onTimeChange(this.contractDateDetail.isPeriodTime, this.contractDateDetail.timestamp);
                    } else {
                        this.datetimeInputInner.value = '';
                    }
                } else {
                    periodTimeComponent.remove();
                    if (this.contractDateDetail.timestamp) this.contractDateDetail.date = new Date(this.contractDateDetail.timestamp);
                }
                // button1.remove();
                button1.lastChild.innerHTML = "今日";

            })
        },
        data() {
            let uniqueId = getUniqueId('meta-', '');
            return {
                metaMessage: new MetaMessage(),
                contracts: [],
                searchTime: null,
                selectedContract: null,
                periodTimeUnitOptions: [
                    {
                        "label": "年",
                        "value": 1
                    },
                    {
                        "label": "季度",
                        "value": 5
                    },
                    {
                        "label": "月",
                        "value": 2
                    },
                ],
                // contractDateDetail: {...this.contractDateInfo},
                contractDateDetail: this.contractDateInfo,
                forceRefresh: true,
                datetimeInputInner: null,
                datePickerUniqueClass: uniqueId,
                datePickerPopperDom: null,
                datePickerInputDom: null,
                disableRecover: false,//用来阻止blur事件还原
            }
        },
        watch: {
            contractDateInfo() {
                //浅拷贝props传来的值,以便input blur时还原数据
                // this.contractDateDetail = {...this.contractDateInfo};
                this.contractDateDetail = this.contractDateInfo;
                // 检查默认值
                if (!this.contractDateDetail.periodTimeNumber) {
                    this.contractDateDetail.periodTimeNumber = 1;
                }
                if (this.contractDateDetail.timestamp) {
                    this.contractDateDetail.date = new Date(this.contractDateDetail.timestamp);
                }
                // 解决多次打开el-radio-group没有刷新的问题
                this.forceRefresh = false;
                this.$nextTick(() => {
                    this.forceRefresh = true
                })
            },
            'contractDateDetail.date': {
                handler(newValue, oldValue) {
                    console.log('contractDateDetail.date  change  newValue:', newValue);
                    console.log('contractDateDetail.date  change  oldValue:', oldValue);
                    if (newValue) {
                        const time = newValue.getTime();
                        this.contractDateDetail.timestamp = time
                        this.onTimeChange(this.contractDateDetail.isPeriodTime, time);
                    }
                }
            }
        },
        methods: {
            handleInputBlur(e) {
                this.datetimeInputInner.classList.remove('el-input__inner--focus');
                if (this.disableRecover || !this.recoverable) return;

                this.contractDateDetail = this.contractDateInfo;
                if (this.contractDateDetail.timestamp) {
                    this.contractDateDetail.date = new Date(this.contractDateDetail.timestamp);
                }
                this.$emit('onBlur', e);
            },
            handleInputFocus(e) {
                this.datetimeInputInner.classList.add('el-input__inner--focus');
            },
            onDatePickerChange: function (data) {
                const time = data.getTime();
                this.contractDateDetail.timestamp = time;
                this.onTimeChange(this.contractDateDetail.isPeriodTime, time);
                this.timeChange && this.timeChange(time);
                this.$emit('onConfirm', this.contractDateDetail);
                this.disableRecover = false;
            },
            periodTimeEffectiveChange: function (value) {
                const time = this.contractDateDetail.timestamp;
                this.onTimeChange(value, time);
            },
            setPeriodTimeText: function (time) {
                let inputText = formatDateFromTimestamp(time, "yyyy-MM-dd") + " 按";
                if (this.contractDateDetail.periodTimeUnit === ContractDatePeriodTimeUnit.MONTH.value) {
                    const day = parseInt(new Date(time).getDate());
                    this.contractDateDetail.timeText = `每月${day}日`;
                    inputText += "月";
                } else if (this.contractDateDetail.periodTimeUnit === ContractDatePeriodTimeUnit.YEAR.value) {
                    const date = new Date(time);
                    this.contractDateDetail.timeText = `每年${parseInt(date.getMonth() + 1)}月${parseInt(date.getDate())}日`;
                    inputText += "年";
                } else if (this.contractDateDetail.periodTimeUnit === ContractDatePeriodTimeUnit.QUARTER.value) {
                    inputText += "季度";
                }
                inputText += "重复" + this.contractDateDetail.periodTimeNumber + "次";
                if (!this.contractDateDetail.id || this.contractDateDetail.id < 0) {
                    this.$nextTick(() => {
                        this.datetimeInputInner.value = inputText;
                    })
                }
            },
            setTimeText: function (time) {
                if (time) {
                    this.contractDateDetail.timeText = formatDateFromTimestamp(time, "yyyy年MM月dd日");
                    this.contractDateDetail.day = formatDateFromTimestamp(time, "yyyy-MM-dd");
                } else {
                    this.contractDateDetail.timeText = '';
                }
                if (!this.contractDateDetail.id || this.contractDateDetail.id < 0) {
                    this.$nextTick(() => {
                        this.datetimeInputInner.value = formatDateFromTimestamp(time, "yyyy-MM-dd");
                    })
                }
            },
            onTimeChange: function (periodTimeEffective, time) {
                if (!time) return;
                if (periodTimeEffective) {
                    this.setPeriodTimeText(time);
                } else {
                    this.setTimeText(time);
                }
            },
            onPeriodTimeNumberChange: function () {
                this.onTimeChange(this.contractDateDetail.isPeriodTime, this.contractDateDetail.timestamp);
                this.$emit('onConfirm', this.contractDateDetail);
            },
            onPeriodTimeUnitChange: function () {
                this.onTimeChange(this.contractDateDetail.isPeriodTime, this.contractDateDetail.timestamp);
                this.$refs['date-picker'].focus()
                this.$emit('onConfirm', this.contractDateDetail);
                // this.$refs['date-picker'].trigger('click');
                // this.$nextTick(() => {
                //     this.$refs['date-picker'].focus();
                // })
            },

        }
    }
</script>

<style scoped>

    .period-time-component {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 0;
        flex: none;
        align-items: center;
        justify-content: space-between;
        text-align: left;
    }

    .period-time-component .icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: default;
    }

    .period-time-checkbox-wrapper {
        display: flex;
    }

    .period-time-select-wrapper {
        margin-right: 16px;
        font-size: 12px;
        white-space: pre-wrap;
        word-break: break-word;
        width: calc(100% - 55px);
        color: #1f2329;
        align-self: center;
        flex: auto;
    }

    .period-time-checkbox {
        color: var(--font-gray);
        margin: 0 12px 0 15px;
    }

</style>
<style>

</style>
