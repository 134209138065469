export const formatDateFromTimestamp = function(timestamp, formatTemplate) {
    var date = new Date(timestamp);
    var yy = date.getFullYear();
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var dd = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var HH = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    var timeString = formatTemplate.replace("yyyy", yy).replace("MM", MM).replace("dd", dd)
        .replace("HH", HH).replace("mm", mm).replace("ss", ss);
    return timeString;
};